<template>
  <div
    class="d-flex flex-row-fluid bgi-size-cover bgi-position-center"
    :style="{
      backgroundImage: `url(${backgroundImage})`,
    }"
  ></div>
</template>

<script>
export default {
  name: "Custom-Error",
  computed: {
    backgroundImage() {
      return process.env.BASE_URL + "media/error/custom-bg.jpg";
    },
  },
};
</script>
